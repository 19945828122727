import React, { useState, useEffect } from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { Container, Row, Col, Card } from "react-bootstrap"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

function SlideShow() {
  const [index, setIndex] = useState(0)
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "contact" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  )
  //Minus 1 for array offset from 0
  const length = allFile.edges.length - 1
  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1)
  const { node } = allFile.edges[index]

  useEffect(() => {
    setTimeout(() => {
      handleNext()
    }, 5000)
  })

  return (
    <div>
      <div>
        <Img
          fluid={node.childImageSharp.fluid}
          key={node.id}
          alt={node.name.replace(/-/g, " ").substring(2)}
          className="rounder"
        />
      </div>
    </div>
  )
}

export default () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Container fluid>
        <Row className="justify-content-center text-white">
          <Col xs={12} md={6}>
            <SlideShow />
          </Col>
        </Row>
        <Row className="justify-content-center text-white mt-2">
          <Col md={3} xl={3}>
            <Card className="bg-dark">
              <Card.Body>
                <h2>Email</h2>
                <a
                  href="mailto:contact@reflektfilm.com"
                  rel="noopener noreferrer"
                >
                  <h4>contact@reflektfilm.com</h4>
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} xl={3} className="mt-2 mt-md-0">
            <Card className="bg-dark">
              <Card.Body>
                <h2>Phone</h2>
                <a href="tel:8583812510" rel="noopener noreferrer">
                  <h4>+18583812510</h4>
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center text-white mt-2">
          <Col md={6}>
            <Card className="bg-dark">
              <Card.Body>
                <h2>Socials</h2>
                <h4>
                  Twitter:{" "}
                  <a
                    href="https://twitter.com/reflektfilm"
                    rel="noopener noreferrer"
                  >
                    @reflektfilm
                  </a>
                </h4>
                <h4>
                  Instagram:{" "}
                  <a
                    href="https://instagram.com/reflektfilm"
                    rel="noopener noreferrer"
                  >
                    @reflektfilm
                  </a>
                </h4>
                <h4>
                  Are.na:{" "}
                  <a
                    href="https://are.na/reflekt-film"
                    rel="noopener noreferrer"
                  >
                    reflektfilm
                  </a>
                </h4>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
